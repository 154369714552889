const events = {
    logout:[]
}

export const logoutAction =  () =>{
    events.logout.forEach(ele => ele())
}


export default events
