<template>

<Header />
  <router-view/>
<Footer />


</template>

<style>
html{
  min-height: 100%;
}
body{
  height: 100vh;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import Header from "@/views/Header";
import Footer from "@/views/Footer";
export default {
  components: {Footer, Header}
}
</script>
