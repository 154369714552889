import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import "bootstrap/scss/bootstrap.scss"
import "bootstrap";
import AuthInterceptorRequest from "@/interceptors/AuthInterceptorRequest";
import AuthInterceptorResponse from "@/interceptors/AuthInterceptorResponse";
axios.interceptors.request.use(AuthInterceptorRequest)
axios.interceptors.response.use((config)=> config,  AuthInterceptorResponse)
createApp(App).use(router).mount('#app')
