<template>
  <footer class="mt-auto bg-dark text-center text-lg-start">
    <div class="text-center text-white p-3" style="background-color: rgba(0, 0, 0, 0.2);">
      © {{ copyright }}  Copyright:
      <a class="text-white" target="_blank" href="https://farmamico.com/">Farmamico.com</a>
    </div>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      copyright: String
    }
  },
  name: "Footer",
  mounted() {
    changeCopy: {
      this.copyright=new Date().getFullYear()
    }
  }

}
</script>

<style scoped>

</style>
