import { createRouter, createWebHistory } from 'vue-router'
import NoAuthGuard from "@/guards/NoAuthGuard";
import AuthGuard from "@/guards/AuthGuard";

const routes = [
   {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter : NoAuthGuard

  },
    {
        path: '/check',
        name: 'check',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wait.vue'),
        beforeEnter : NoAuthGuard

    },
  {
    path: '/autologin',
    name: 'autologin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Autologin.vue')

  },
    {
        path: '/verifica',
        name: 'verify',
        component: () => import(/* webpackChunkName: "about" */ '../views/VerificaEmail.vue')

    },
  {
    path: '/live',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    beforeEnter : AuthGuard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
